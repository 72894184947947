.boxOptionsTitle {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0;
    padding: 0;
    list-style: none;
    width: 100%;
    // border-bottom: 1px dashed #ec430d;
    li {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 8px 10px;
        text-align: center;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        color: #333;
        font-weight: 900;
        width: -webkit-fill-available;
        margin: 0 4px;
        font-size: 11px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .active {
        background-color: rgba(236, 67, 13, 0.05);
        border: 0.5px solid #ec430d;
        color: #ec430d;
    }
}

.boxOptionsDishType {
    flex-wrap: wrap;
    li {
        width: 40%;
    }
}

.mealBoxContainer {
    h5 {
        text-align: center;
        color: #ec430d;
        font-weight: 900;
        font-size: 14px;
        margin: 12px 0 8px;
    }
    .mealboxBanner {
        width: 100%;
        border-radius: 10px;
    }
    button {
        background-color: #ec430d;
        color: white;
        font-size: 14px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        display: inline-block;
        padding: 12px;
        margin: 20px auto 0;
        width: 70%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.mealBoxItemContainer {
    display: flex;
    flex-wrap: wrap;
    // border-top: 1px dashed #ec430d;
    h5 {
        width: 100%;
    }
}
