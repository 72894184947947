.sectionTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
    font-weight: 500;
    font-family: Fredoka;
}

.landingSection {
    border-bottom: 1px dashed lightgrey;
    padding: 20px 0;
}

.whiteBG {
    background: #fff;
    padding: 20px 10px;
}

.serviceTypeSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .whiteBG {
        background: #f4f4f9;
    }
    .landingSection {
        border: 0;
    }
}