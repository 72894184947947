.checkoutPage {
    margin: 0 10px;
    section {
        border-bottom: 1px dashed gray;
        padding: 10px 0;
    }
    .originalPrice {
        font-size: 11px;
        text-decoration: line-through;
        color: gray;
    }
    .discountedPrice {
        color: #ec430d;
    }
    .guestCountSection {
        display: flex;
        align-items: center;
        position: relative;
        input {
            margin: 0;
            width: 10%;
            padding: 10px;
            text-align: center;
            border: 1px solid #DAE2ED;
            border-radius: 5px;
            font-size: medium;
        }
        section {
            border: 0;
            position: absolute;
            right: 0;
        }
    }
    .pricePaxSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .key {
            font-weight: 900;
            margin: 5px 0;
        }
    }

    .menuSection {
        p {
            font-weight: 900;
            text-transform: capitalize;
        }
        ul {
            list-style: disc;
            li {
                line-height: 25px;
                text-transform: capitalize;
            }
        }
        .MuiFormControl-root {
            width: 100%;
            background: #fff;
            margin: 10px 0;
        }
    }

    .menuItemsSection {
        padding: 0;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        p {
            margin: 0;
            font-size: 16px;
            background: #fafafa;
            border-bottom: 1px solid #DAE2ED;
            border-top: 1px solid #DAE2ED;
            padding: 10px;
            color: #ec430d;
        }
        ul {
            padding-bottom: 12px;
            padding-top: 12px;
            margin: 0;
        }
        .textarea {
            width: 100% !important;
        }
    }

    .contactSection {
        p {
            font-weight: 900;
            background: #f4f4f9;
            margin: 20px 0 10px 0;
            padding: 5px 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 16px 0;
                .MuiFormControl-root {
                    width: 100%;
                    background: #fff;
                }
            }
        }
    }

    .isServiceSection {
        .MuiCheckbox-root {
            padding: 0;
        }
        .Mui-checked {
            color: #ec430d;
        }
        span {
            font-size: 12px;
        }
    }

    .finalPriceSection {
        color: #ec430d;
        font-size: 16px;
    }

    .discount {
        color: #c5312d;
        .subtext {
            color: #c5312d;
            opacity: 0.7;
        }
    }
}