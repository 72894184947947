.about-us {
    .about-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      max-width: 1200px;
      margin: 0 auto;
  
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 40px;
      }
  
      .about-text {
        flex: 1;
        text-align: center;
  
        @media (min-width: 768px) {
          text-align: left;
        }
  
        h2 {
          
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 20px;
          color: #333;
  
          @media (min-width: 1024px) {
            font-size: 2.5rem;
          }
        }
  
        p {
          font-size: 0.9rem;
          line-height: 1.6;
          color: #666;
          margin: 0;
  
          @media (min-width: 768px) {
            font-size: 1rem;
          }
  
          .highlight {
            color: #ff5722;
            font-weight: bold;
          }
        }
  
        .social-media {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          gap: 15px;
  
          @media (min-width: 768px) {
            justify-content: flex-start;
          }
  
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: #ffffff;
            color: #ff5722;
            border-radius: 50%;
            font-size: 18px;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
  
            &:hover {
              background-color: #ff5722;
              color: #ffffff;
              transform: scale(1.1);
            }
          }
        }
      }
  
      .about-image {
        flex: 1;
        text-align: center;
  
        img {
          width: 100%;
          max-width: 500px;
          border-radius: 16px;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  
          @media (min-width: 768px) {
            max-width: 600px;
          }
        }
      }
    }
  }
  