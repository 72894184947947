  
  .how-it-works-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 10px;
  }
  
  .steps {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .how-it-works-steps {
    padding-left: 5%;
  }
  
  .step {
    display: flex;
    align-items: center;
    gap: 1rem;
    p {
        text-align: left;
        font-family: Fredoka;
        color: #000;
        font-weight: 500;
    }
  }
  
  .icon {
    font-size: 2rem;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f0f0f0;
  }
  
  .yellow-icon {
    color: #ffcc00;
  }
  
  .highlight-box {
    font-weight: bold;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
  }
  
  .green {
    background-color: #32cd32;
    color: #fff;
  }
  
  .red {
    background-color: #ff6347;
    color: #fff;
  }
  
  .video-preview {
    text-align: left;
  }
    
  .video-container {
    display: flex;
    gap: 1rem;
  
    .thumbnail {
      position: relative;
      width: 48%;
      cursor: pointer;
  
      .thumbnail-image {
        width: 100%;
        height: 295px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
  
      iframe {
        width: 100%;
        border: none;
        border-radius: 8px;
      }
    }
  }

  @media (min-width: 768px) {
    .how-it-works-content {
      align-items: center;
    }
      
    .how-it-works-steps {
      padding-left: 0;
    }
}

  