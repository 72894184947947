.allLoaderBg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ec430d;
    z-index: 1000;
    img {
        width: 100%;
        top: 50%;
        position: absolute;
        transform: translate(0, -50%);
    }
}

@media (min-width: 768px) {
    .allLoaderBg {
        img {
            width: 40%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
        }
    }
}
  