.createMenu {
    position: relative;
    padding: 5px;
    header {
        text-align: center;
        font-family: Fredoka;
        h1 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
        }
        p {
            font-size: 12px;
        }
    }
}

.notFoundMessage {
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    color: #ec430d;
    font-size: 18px;
    font-weight: 900;
    margin-top: 50%;
}