.wrapper {
    padding: 55px 0 0;
}

.isFooter {
    padding-bottom: 20%;
}

.chatBot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    width: 15%;
    background: #fff;
    padding: 10px 10px 0;
    box-sizing: border-box;
    border: 1px dotted lightgray;
    border-radius: 10px;
    svg {
        color: #ec430d;
        width: 100%;
        height: 100%;
    }
    p {
        font-size: 8px;
        font-family: Fredoka;
        font-weight: 600;
        text-align: center;
    }
}

@media screen and (min-width: 30em) and (orientation: landscape) {
    .wrapper {
        width: 50%;
        margin: auto;
        padding: 3% 5%;
        box-sizing: border-box;
    }
    .isFooter {
        padding-bottom: 5%;
    }

    .chatBot {
        display: none;
    }
}