.ModalContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    .Modal {
        position: relative;
        background-color: #fff;
        width: 70%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -100px);
        padding: 15px 25px;
        position: relative;
        border-radius: 10px;
        border: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        h4 {
            text-align: center;
            width: 100%;
            color: #ec430d;
            margin: 10px 0;
            font-size: 16px;
        }
        p {
            text-align: center;
            color: #ec430d;
            font-size: 14px;
        }
    }
}

@media screen and (min-width: 30em) and (orientation: landscape) {
    .ModalContainer {
        .Modal {
            width: 15%;
        }
    }
}