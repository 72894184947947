$primary-color: #ec430d;

.MenuListContainer {
    margin: 5px 5px 10px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    img {
        border-radius: 5px; 
        height: 150px;
        object-fit: cover;
    }
    h4 {
        margin: 8px 0 5px;
        color: $primary-color
    }
    .categoriesCount {
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 8px 0;
        font-weight: 500;
    }
    .pricePersonSection {
        display: flex;
        justify-content: space-between;
        border-top: 1px dashed #DAE2ED;
        border-bottom: 1px dashed #DAE2ED;
        font-size: 11px;
        div {
            padding: 5px 0;
            font-weight: 900;
        };
        span {
           color: grey; 
           font-size: 10px;
        }
    }
    .vegLogo {
        position: absolute;
        height: 6%;
        right: 16px;
        top: 16px;
        object-fit: contain;
        background: #fff;
        padding: 0px;
        border-radius: 0;
    }
    .offerSection {
        p {
            text-align: left;
            font-weight: 500;
            color: $primary-color;
            font-size: 11px;
            margin: 5px 0 0 0;
        }
        span {
            font-size: 12px;
            font-weight: 900;
        }
    }
}

@media (min-width: 768px) {
    .MenuListContainer {
        width: 45%;
        margin: 8px auto;
        img {
            height: 200px;
        }
    }
  }
  