$primary-color: #ec430d;

.home {
    text-align: center;
    h3 {
        margin-top: 0;
        text-align: center;
    }
}

h1 {
    color: $primary-color;
    font-size: 24px;
    margin-bottom: 20px;
}

@media screen and (min-width: 30em) and (orientation: landscape) {
    .home {
        display: flex;
        flex-wrap: wrap;
    }
}