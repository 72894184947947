.pricingSection {
    font-size: 14px;
    padding: 10px;
    .subtext {
        font-size: 10px;
        color: gray;
    }
}

.packagingCharges {
    .originalPrice {
        font-size: 10px !important;
        color: #c5312d !important;
    }
    .discountedPrice {
        color: #333 !important;
    }
}