.ItemCardContainer {
    position: relative;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 7.5px;
    box-sizing: border-box;
    width: -webkit-fill-available;
    background-color: #fff;
    border: 1px solid #f4f4f9;
    .imageSection {
        width: 35%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .detailsSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        width: 100%;
        .nameLogoContainer {
            color: #333;
            text-transform: capitalize;
            position: relative;
            .itemInfo {
                color: #333;
                font-size: 10px;
                margin-top: 8px;
                text-transform: none;
            }
        }
        span {
            color: #333;
            font-size: 10px;
            margin-bottom: 8px;
        }
        button, p   {
            margin:0;
        }
        button {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    };
    .vegLogo {
        position: absolute;
        top: 10%;
        transform: translateY(-50%);
        right: -15px;
        width: 8px;
        border-radius: 0;
    }
}