.header {
    height: 50px;
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background: #ec430d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: #fafafa;
        font-weight: 600;
        font-family: Fredoka;
    }
    img {
        height: 300%;
        width: 50%;
    }
    svg {
        color: #fff;
        width: 20px;
        height: 20px;
    }
}

@media (min-width: 768px) {
    .header {
        padding: 0 35vw;
    }
  }