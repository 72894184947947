$primary-color: #ec430d;

.serviceTypeContainer {
    .tag {
        color: #ec430d;
        font-size: 12px;
        margin: 0;
    }
    margin: 5px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 47%;
    box-sizing: border-box;
    position: relative;
    h3, p {
        text-align: center; 
        color: #000;
        font-family: Fredoka;
        font-weight: 600;
        margin: 10px 0 5PX;
    }
    h3 {
        font-size: 12px;
    }
    img {
        border-radius: 10px; 
        object-fit: contain;
        height: 115px;
    }
    h4 {
        margin: 10px 0 5px 0;
        color: $primary-color
    }
    h5 {
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .description {
        p {
            text-align: start;
            margin: 10px 5px 5px;
            line-height: 18px;
            font-weight: 500;
        }
        b {
            font-size: 12px;
        }
    }
    .vegLogo {
        position: absolute;
        height: 6%;
        right: 16px;
        top: 16px;
        object-fit: contain;
        background: #fff;
        padding: 0px;
        border-radius: 0;
    }
    .offer {
        p {
            font-weight: 500;
            color: $primary-color;
            font-size: 11px;
            margin: 0;
        }
        span {
            font-size: 13px;
            font-weight: 900;
        }
    }
}

.fullWidth {
    width: -webkit-fill-available !important;
    p {
        font-size: 16px !important;
    }
}

@media (min-width: 768px) {
    .serviceTypeContainer {
        width: 48.5%;
        img {
            min-height: 200px;
        }
    }
}