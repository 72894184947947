$app-bg-color: #f4f4f9;
$primary-color: #ec430d;
$text-color: #333;

.menu {
   padding: 0 5px;
   h4 {
    text-align: center;
    margin: 10px 0 15px;
   }
  .menu-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    select,
    input {
      padding: 10px;
      margin: 5px;
      border: 1px solid $primary-color;
      border-radius: 5px;
    }

    button {
      padding: 10px 20px;
      border: none;
      background-color: $primary-color;
      color: white;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  .menu-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;

    .menu-category {
      h2 {
        color: $primary-color;
        font-size: 20px;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 10px;
          background: $app-bg-color;
          margin: 5px 0;
          border-radius: 5px;
          color: $text-color;
        }
      }
    }
  }

  .sectionHeader {
    display: flex;
    justify-content: center;
    p {
        font-weight: 500;
        font-family: Fredoka;
        font-size: 18px;
        color: $primary-color;
        text-transform: capitalize;
    }
  }

  .sectionItems {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .menu {
    .menu-section {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    h4 {
      font-size: 24px;
      margin: 24px 0;
    }
  }
}
