.hidden {
    visibility: hidden;
    width: 0;
    height: 0;
}

.confirmSection {
    text-align: center;
    padding-top: 25px;
    border-top: 1px dashed lightgray;
    input {
        background-color: #ec430d;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        display: inline-block;
        padding: 10px 20px;
    }
}
