$primary-color: #ec430d;

.MealBoxContainer {
    .MealBoxSection {
        width: 70%;
        padding: 0 10px;
        .itemsCount {
            color: grey;
            font-size: 10px;
            margin: 10px 0;
        }
    }
    span {
        font-size: 12px;
     }
    margin: 5px 5px;
    width: 100%;
    background: white;
    padding: 7.5px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    p {
        font-size: 12px;
        font-weight: 600;
        color: #333;
        margin: 5px 0;
        text-align: left;
        color: #333;
    }
    img {
        border-radius: 5px; 
        height: 100%;
        width: 25%;
        object-fit: cover;
        background-color: #fafafa;
    }
    h4 {
        margin: 5px 0 10px 0;
        color: $primary-color
    }
    .priceSection {
        display: flex;
        justify-content: space-between;
        border-top: 1px dashed #DAE2ED;
        border-bottom: 1px dashed #DAE2ED;
        span {
           color: grey; 
           font-size: 10px;
        }
    }
    .vegLogo {
        position: absolute;
        height: 10%;
        right: 16px;
        top: 16px;
        object-fit: contain;
        background: #fff;
        padding: 0px;
        border-radius: 0;
        width: auto;
    }
    .offer {
        p {
            font-weight: 500;
            color: $primary-color;
            font-size: 11px;
            margin: 0;
        }
        span {
            font-size: 13px;
            font-weight: 900;
        }
    }
}