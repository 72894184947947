.optionsContainerMeal {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;   
}

.celebration-meal-section {
    padding: 5px 5px 225px 5px;
}

.needMealSection {
    display: flex;
    border: 1px dashed lightgray;
    border-left: 0;
    border-right: 0;
    margin: 10px 0;
    margin: 10px 0 0;
    justify-content: center;
    svg {
        color: #ec430d; 
    }
    .MuiCheckbox-root {
        padding: 0;
        margin-right: 5px;
    }
    .Mui-checked {
        color: #ec430d !important;
    }
    p {
        color: #ec430d;
    }
}

.addMealSection {
    display: flex;
    flex-direction: column;
    border-left: 0;
    border-right: 0;
    margin: 10px 0;
    margin: 0;
    justify-content: center;
    width: calc(100% - 10px);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 72px;
    img {
        width: 100%;
    }
    p {
        text-align: center;
        font-family: Fredoka;
        font-weight: 500;
        color: #ec430d;
    }
}