$primary-color: #ec430d;
$button-hover-color: #ff847a;
$button-shadow: rgba(0, 0, 0, 0.1);

.add-button, .added-button {
    background-color: #ec430d;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: inline-block;
    margin: 0;
}
.added-button {
    background-color: green; 
}

@media (min-width: 768px) {
  .add-button {
    font-size: 18px;
  }
}
